import _ from "lodash";
import React, { Fragment } from "react";
import PaymentsManagement from "../../../userPayments/PaymentsManagement"

import {toast} from "react-toastify";

import ExtendedPaymentsSummary from "./ExtendedPaymentsSummary";
import DuePaymentsList from "../../../userPayments/DuePaymentsList";
import PaymentsList from "../../../userPayments/PaymentsList";
import CommentSection from "../../../CommentSection";
import InvoicesSummary from "./InvoicesSummary";

import swal from "sweetalert2";

import {indexById, csrfToken, findAndGet} from "../../../utils/";
import SwitchPayerModal from "../../../userPayments/SwitchPayerModal";
import * as api from "../../../../tools/api";
import {redirectTo} from "../../../../tools/url";
import ModalBillingPayers from "./ModalBillingPayers";

const SEASON_STORED_KEY = "PaymentsSummarySelectedSeason";

function calculateTotals(duePayments, payments, itemsForPayment) {
    const totalDue = _.reduce(
        _.map(itemsForPayment, d => d.discountedTotal),
        (sum, f) => sum + f,
        0.0
    );

    const previsionalTotal = _.chain(duePayments)
        .values()
        .flatten()
        .map(dp => parseFloat(dp.adjusted_amount))
        .reduce((sum, n) => (sum + n), 0)
        .value();

    const totalPayments = _.chain(payments)
        .values()
        .flatten()
        .map(p => parseFloat(p.adjusted_amount))
        .reduce((sum, n) => (sum + n), 0)
        .value();

    const totalPayback = _.chain(payments)
        .values()
        .flatten()
        .map(p => parseFloat(p.adjusted_amount))
        .compact()
        .reduce((sum, n) => (sum + n), 0)
        .value();

    const totalPaymentsToDay = _.chain(payments)
        .values()
        .flatten()
        .filter(
            p =>
                !p.payment_status_id && Date.parse(p.cashing_date) <= Date.now()
        )
        .map(p => parseFloat(p.adjusted_amount))
        .reduce((sum, n) => (sum + n), 0)
        .value();

    return {
        totalDue,
        totalPayback,
        totalPayments,
        previsionalTotal,
        totalPaymentsToDay,
    };
}

function roundCurrency(value, digits = 2) {
    return Number(value.toFixed(digits));
}

function getDiscountedAmount(amount, percentOff) {
    const res = (amount || 0) * (1 - percentOff / 100);
    return roundCurrency(res);
}

function generateDataForPaymentSummaryTable({
                                                activities,
                                                desired,
                                                options,
                                                seasonId,
                                                seasons,
                                                adhesions,
                                                adhesionPrices,
                                                adhesionEnabled,
                                                packs,
                                                user,
                                                adhesion_billings = []
                                            }) {
    let data = [];

    const seasonActivities = activities.filter(a =>
        desired.find(d => d.activity_id === a.activity.id)
    );

    // Et ensuite une ligne par activité
    _.forEach(seasonActivities, act => {
        const a = act.activity;
        const des = _.find(
            desired,
            d =>
                d.activity_id === act.activity_id &&
                d.activity_application.user_id === act.user_id
        );

        if (des) {
            const activity_nb_lessons = a.intended_nb_lessons

            const season = seasons.find(s => s.id === seasonId);
            const priceAssociations = []

            a.activity_ref.activity_ref_pricing.forEach(arp => {
                if (new Date(arp.from_season.start) <= new Date(season.start) && (!arp.to_season || new Date(arp.to_season.end) >= new Date(season.end))) {
                    priceAssociations.push(arp);
                }
            })

            const priceAssociation = priceAssociations.find(pa => pa.pricing_category_id === des.pricing_category_id)

            let amount = 0;
            if (priceAssociation && priceAssociation.price) {
                amount = _.round((priceAssociation.price / activity_nb_lessons) * (des.prorata || activity_nb_lessons), 2);
            }

            const coupon = _.get(des, "discount.coupon", 0);
            const percent_off = _.get(des, "discount.coupon.percent_off", 0);

            data.push({
                id: des.id,
                activity: `${a.activity_ref.label} (${a.activity_ref.kind})`,
                stopped_at: des.activity_application.stopped_at,
                intended_nb_lessons: a.intended_nb_lessons,
                des,
                ref: a.activity_ref,
                prorata: des.prorata,
                coupon: {...coupon},
                studentId: act.id,
                user: act.user,
                pricingCategoryId: des.pricing_category_id,
                activityId: a.id,
                paymentLocation: act.payment_location,
                due_total: amount || 0,
                discountedTotal: getDiscountedAmount(amount, percent_off),
                unitPrice: priceAssociation && priceAssociation.price ? _.round((priceAssociation.price / activity_nb_lessons), 2) : 0,
            });
        }
    });

    const taken = data.map(d => d.activityId);
    const takenDesired = data.map(d => d.id);

    _.forEach(options, option => {
        if (_.includes(taken, option.activity.id)) {
            return;
        }
        const a = option.activity;
        const des = _.find(desired, d => d.id === option.desired_activity_id);

        if (des && !takenDesired.includes(des.id)) {
            takenDesired.push(des.id);

            const activity_nb_lessons = a.intended_nb_lessons
            const season = seasons.find(s => s.id === seasonId);
            const priceAssociations = []

            a.activity_ref.activity_ref_pricing.forEach(arp => {
                if (new Date(arp.from_season.start) <= new Date(season.start) && (!arp.to_season || new Date(arp.to_season.end) >= new Date(season.end))) {
                    priceAssociations.push(arp);
                }
            })

            const priceAssociation = priceAssociations.find(pa => pa.pricing_category_id === des.pricing_category_id)

            let amount = 0;
            if (priceAssociation && priceAssociation.price) {
                amount = _.round((priceAssociation.price / activity_nb_lessons) * (des.prorata || activity_nb_lessons), 2);
            }

            const coupon = _.get(des, "discount.coupon", 0);
            const percentOff = _.get(des, "discount.coupon.percent_off", 0);

            const formattedOption = {
                id: des.id,
                activity: `${a.activity_ref.label} (${a.activity_ref.kind})`,
                intended_nb_lessons: a.intended_nb_lessons,
                des,
                ref: des.activity_ref,
                prorata: des.prorata,
                coupon: coupon,
                studentId: option.id,
                user: option.desired_activity.activity_application.user,
                pricingCategoryId: des.pricing_category_id,
                paymentLocation: option.payment_location,
                due_total: amount || 0,
                discountedTotal: getDiscountedAmount(amount, percentOff),
                isOption: true,
                unitPrice: priceAssociation && priceAssociation.price ? _.round((priceAssociation.price / activity_nb_lessons), 2) : 0,
            };
            data.push(formattedOption);
        }
    });

    if (adhesionEnabled) {
        for (const adhesion of adhesions) {
            const adhesionPrice = adhesionPrices.find(p => p.id === adhesion.adhesion_price_id) || {};

            if (adhesionPrice) {
                const coupon = _.get(adhesion, "discount.coupon", 0);
                const percentOff = _.get(adhesion, "discount.coupon.percent_off", 0);

                data.push({
                    id: 0,
                    activity: `Adhésion de ${adhesion.user.first_name} ${adhesion.user.last_name}`,
                    frequency: 1,
                    initial_total: 1,
                    due_total: adhesionPrice.price || 0,
                    coupon: coupon,
                    discountedTotal: getDiscountedAmount(adhesionPrice.price, percentOff),
                    unitPrice: adhesionPrice.price || 0,
                    user: adhesion.user,
                    studentId: adhesion.user.id,
                    adhesionPriceId: adhesionPrice.id,
                    adhesionId: adhesion.id,
                    billing_logs: adhesion_billings,
                });
            }
        }
    }

    if ((packs || []).length > 0)
    {
        const userPacks = packs.filter(p => p.user_id === user.id)

        if (userPacks.length > 0)
        {
            for (const pack of userPacks)
            {
                const pack_price = pack.activity_ref_pricing.price;
                const coupon = _.get(pack, "discount.coupon", 0);
                const percentOff = _.get(pack, "discount.coupon.percent_off", 0);

                data.push({
                    id: 0,
                    activity: `Pack de ${user.first_name} ${user.last_name} pour ${pack.activity_ref.label} (${pack.activity_ref.kind})`,
                    frequency: 1,
                    initial_total: 1,
                    due_total: pack_price || 0,
                    coupon: coupon,
                    discountedTotal: getDiscountedAmount(pack_price || 0, percentOff),
                    unitPrice: pack_price || 0,
                    user: user,
                    studentId: user.id,
                    packPrice: pack.activity_ref_pricing,
                    packId: pack.id,
                });
            }
        }
    }

    return data;
}


export default class ExtendedPaymentsManagement extends PaymentsManagement {
    constructor(props) {
        super(props);
        if (props.outcome)
            this.state = {
                ...this.state,
                draw_toast: true,
            };

        this.handlePayPayment = this.handlePayPayment.bind(this);
        this.handlePayDuePayment = this.handlePayDuePayment.bind(this);
    }

    handleCreatePaymentSuccess(new_payment) {
        if (new_payment) {
            const payer = this.props.user;

            const allDuePayments = this.state.duePayments;
            const duePayments = allDuePayments[payer.id] || [];
            const duePaymentIndex = duePayments.findIndex(dp => dp.id == new_payment.due_payment_id);
            duePayments.splice(duePaymentIndex, 1);
            duePayments.push(new_payment.due_payment);

            const allPayments = this.state.payments;
            const payments = allPayments[payer.id] || [];
            payments.push(new_payment);

            // actualisation des données des composants fils
            this.setState({
                duePayments: allDuePayments,
                payments: allPayments,
            });

            // appel à l'API pour déclencher le paiement
            this.handlePayPayment(new_payment.id);
        }

    }

    handlePayPayment(newPaymentId) {
        if (this.props.isStudentView && !(this.state.payers[0] || {}).address) {
            swal({
                title: "Erreur",
                type: "error",
                text: "Impossible d'effectuer le règlement. Veuillez renseigner votre adresse postale."
            });

            return;
        }

        api.set()
            //.before(() => this.setState({ isFetching: true }))
            .success((res) => {
                console.log("pay_payment : ", res)

                // on redirige l'utilisateur vers l'URL pour qu'il fasse le paiement
                redirectTo(res.url)

            })
            .error((res) => {
                console.log("pay_payment : ", res)
                swal({
                    title: "error",
                    type: "error",
                    text: "Impossible d'effectuer le règlement"
                });
            })
            .post(`/users/student_payments/${newPaymentId}/pay`);
    }

    handlePayDuePayment(duePaymentId) {
        if (this.props.isStudentView && !(this.state.payers[0] || {}).address) {
            swal({
                title: "Erreur",
                type: "error",
                text: "Impossible d'effectuer le règlement. Veuillez renseigner votre adresse postale."
            });

            return;
        }

        api.set()
            .success((new_payment) => this.handleCreatePaymentSuccess(new_payment))
            .error((res) => {
                swal({
                    title: "error",
                    type: "error",
                    text: "Impossible de créer le paiement"
                });
                console.log("create_payment_for_due_payment : ", res)
            })
            .post(
                "/users/student_payments",
                {due_payment_id: duePaymentId}
            );
    }


    handleShowInvoice(paymentId) {
        api.set()
            .success(({invoice_url}) => {
                console.log(invoice_url);
                window.open(invoice_url, '_blank', 'location=yes,height=800,width=520,scrollbars=yes,status=yes');
            })
            .error((res) => {
                swal({
                    title: "error",
                    type: "error",
                    text: "Impossible d'afficher la facture"
                });
                console.log("show invoice : ", res)
            })
            .get(
                "/users/student_payments/invoice",
                {payment_id: paymentId}
            );
    }

    shouldDisplayPayButton(duePaymentId) {
        const allPayments = _.chain(this.state.payments)
            .values()
            .flatten()
            .value();
        return allPayments.findIndex(p => p.due_payment_id === duePaymentId) < 0;
    }

    shouldDisplayRetryButton(paymentId) {
        const allPayments = _.chain(this.state.payments)
            .values()
            .flatten()
            .value();
        const payment = allPayments.find(p => p.id === paymentId);
        return payment != undefined && payment.payment_status_id != 1;
    }

    shouldRenderModalBillingPayers() {
        const {isStudentView, isInvoicesActivated, itemsForPayment} = this.props;
        if (!isStudentView || !isInvoicesActivated || !itemsForPayment) return false;

        const hasBillingLogs = itemsForPayment.some(data => (data.des || {}).billing_logs || data.billing_logs);
        return hasBillingLogs;
    }

    displayToastIfRequired() {
        if (this.state.draw_toast) {
            toast.info(
                <div>
                    Paiement {this.props.outcome === 'success' ? 'effectué avec succès' : 'échoué'}. En attente de la
                    confirmation du serveur...
                    <div className="sk-spinner sk-spinner-fading-circle m-n" style={{display: "inline-block"}}>
                        <div className="sk-circle1 sk-circle"></div>
                        <div className="sk-circle3 sk-circle"></div>
                        <div className="sk-circle4 sk-circle"></div>
                        <div className="sk-circle5 sk-circle"></div>
                        <div className="sk-circle6 sk-circle"></div>
                        <div className="sk-circle7 sk-circle"></div>
                        <div className="sk-circle8 sk-circle"></div>
                        <div className="sk-circle9 sk-circle"></div>
                        <div className="sk-circle10 sk-circle"></div>
                        <div className="sk-circle11 sk-circle"></div>
                        <div className="sk-circle12 sk-circle"></div>
                    </div>
                </div>, {position: toast.POSITION.BOTTOM_CENTER}
            )

            setTimeout(() => this.waitForCheckoutSessionCompleted(this.props.csId), 10);
        }
    }

    waitForCheckoutSessionCompleted(csId) {

        api.set()
            .success((res) => {

                if (res.payment_succeeded) {
                    this.setState({draw_toast: false});
                    toast.dismiss();

                    swal({
                        title: "Paiement confirmé",
                        text: "Le paiement a bien été confirmé. La page va se recharger pour refléter les modifications.",
                        type: "info",
                        confirmButtonText: "OK"
                    }).then(() => {
                        let url = new URL(window.location);
                        window.location = url.origin + url.pathname;  // recharger la page sans les paramètres dans l'URL
                    });

                } else {
                    setTimeout(() => this.waitForCheckoutSessionCompleted(csId), 5000);
                }

            })
            .error((res) => {
                swal({
                    title: "error",
                    type: "error",
                    text: "Problème technique lors de la confirmation du paiement"
                });
                console.log("confirm_payment_intent_succeeded : ", res)
            })
            .get(
                "/users/student_payments/payment_succeeded",
                {cs_id: csId}
            );
    }


    render() {
        const payer = this.props.user;

        const itemsForPayment = generateDataForPaymentSummaryTable({
            activities: this.state.activities,
            desired: this.state.desiredActivities,
            options: this.state.options,
            seasonId: this.state.season,
            seasons: this.props.seasons,
            adhesionPrices: this.props.adhesionPrices,
            adhesionEnabled: this.props.adhesionEnabled,
            adhesions: this.state.adhesions,
            packs: (this.props.packs || {})[this.state.season],
            user: this.props.user,
            adhesion_billings: this.props.billingLogs[this.state.season]
        });

        const duePaymentsButtons = [
            {
                class: "btn btn-primary btn-xs m-r-sm",
                icon: "fas fa-shopping-cart",
                key: "pay",
                onClick: (id) => this.handlePayDuePayment(id),
                shouldDisplay: (id) => this.shouldDisplayPayButton(id)
            },
        ];

        const paymentsButtons = [
            {
                class: "btn btn-primary btn-xs m-r-sm",
                icon: "fas fa-shopping-cart",
                key: "pay",
                onClick: (id) => this.handlePayPayment(id),
                shouldDisplay: (id) => this.shouldDisplayRetryButton(id)
            },
        ];

        const duePaymentListExtraButtons = this.props.isPaymentAvailable && this.props.isStudentView ?
            duePaymentsButtons
            :
            null;

        const paymentListExtraButtons = this.props.isPaymentAvailable && this.props.isStudentView ?
            paymentsButtons
            : null;

        const {
            totalDue,
            totalPayback,
            totalPayments,
            previsionalTotal,
            totalPaymentsToDay,
        } = calculateTotals(
            this.state.duePayments,
            this.state.payments,
            itemsForPayment
        );

        const globalLocation =
            (Object.keys(this.state.schedules).length > 0 &&
                _.reduce(
                    this.state.schedules,
                    (acc, s) => (s.location_id == acc ? acc : null),
                    Object.entries(this.state.schedules)[0][1].location_id
                )) ||
            null;

        const generateStatusSelection = _.map(
            this.props.schedule_statuses,
            (s, i) => {
                return (
                    <div key={s.id} className="radio radio-primary">
                        <input
                            type="radio"
                            name="status"
                            value={s.id}
                            checked={this.state.schedule_status_id == s.id}
                            onChange={e => this.handleChangeStatus(e)}
                            id={s.id}
                        />
                        <label htmlFor={s.id}>
                            <span>{s.label}</span>
                        </label>
                    </div>
                );
            }
        );

        const previousSeason = findAndGet(
            this.props.seasons,
            s => s.id === this.state.season,
            "previous"
        );

        let previousSeasonCreditNotes = null;
        let previousSeasonBalance = 0;

        if (previousSeason) {
            previousSeasonCreditNotes =
                previousSeason &&
                _(
                    _.find(
                        this.state.paymentsBySeason,
                        (_, sId) => previousSeason.id == sId
                    )
                )
                    .map(a => a)
                    .flatten()
                    .filter("payment_method.is_credit_note")
                    .value();

            const previousSeasonState =
                previousSeason &&
                this.getSeasonDependentState(previousSeason.id);
            const {previsionalTotal, totalPayments} =
            previousSeason &&
            calculateTotals(
                previousSeasonState.duePayments,
                previousSeasonState.payments,
                generateDataForPaymentSummaryTable({
                    activities: this.state.activities,
                    desired: previousSeasonState.desiredActivities,
                    options: this.state.options,
                    seasonId: previousSeason.id,
                    seasons: this.props.seasons,
                    adhesionPrices: this.props.adhesionPrices,
                    adhesionEnabled: this.props.adhesionEnabled,
                    adhesions: previousSeasonState.adhesions,
                    packs: (this.props.packs || {})[this.state.season],
                    user: this.props.user,
                    adhesion_billings: this.props.billingLogs[previousSeason]
                })
            );

            previousSeasonBalance = _.floor(
                previsionalTotal - totalPayments,
                2
            );
        }

        this.displayToastIfRequired();

        return (
            <React.Fragment>
                {this.props.isStudentView ?
                    ""
                    :
                    <div className="payment-print">
                        <div className="payment-print-identity">
                            <div className="row">
                                <div className="col-xs-8">
                                    {_(this.props.activities)
                                        .map(a => a.user)
                                        .compact()
                                        .uniqBy(u => u.id)
                                        .map(u => (
                                            <p
                                                key={u.id}
                                            >{`${u.first_name} ${u.last_name} N°${u.adherent_number}`}</p>
                                        ))
                                        .value()}
                                </div>
                                <div className="col-xs-4 text-right">
                                    <p>
                                        {
                                            this.props.seasons.find(
                                                s => s.id === this.state.season
                                            ).label
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="payment-print-content">
                            <h3 className="payment-print-payer">
                                Payeur.s :{" "}
                                {this.state.payers.map((p, i, a) => (
                                    <b
                                        key={i}
                                    >{`${p.first_name} ${p.last_name}`}</b>
                                ))}
                            </h3>

                            <hr/>

                            <div className="row payment-print-checkbox-section">
                                <div className="col-xs-6">
                                    <h2>Moyen de paiement</h2>
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        Prélèvement
                                    </h3>
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        Chèque
                                    </h3>
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        Espèces
                                    </h3>
                                </div>
                                <div className="col-xs-6">
                                    <h2>Nombre d'échéance(s)</h2>
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        Annuel
                                    </h3>
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        Trimestriel (Octobre/Janvier/Avril)
                                    </h3>
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        Mensuel (indiquer nombre d'échéances et
                                        premier mois)
                                    </h3>
                                </div>
                            </div>
                            <div className="row payment-print-checkbox-section">
                                <div className="col-xs-6">
                                    <h2>Pour les prélèvements, date souhaitée</h2>
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        5 du mois
                                    </h3>
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        15 du mois
                                    </h3>
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        28 du mois
                                    </h3>
                                </div>
                            </div>
                            <div className="row payment-print-checkbox-section">
                                <div className="col-lg-12">
                                    <div>
                                        <h2>Adhésion réglée différemment</h2>{" "}
                                        <h3>
                                            <span className="payment-print-checkbox"></span>
                                            Oui{" "}
                                            <span className="payment-print-checkbox"></span>
                                            Non
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row payment-print-checkbox-section">
                                <div className="col-xs-6">
                                    <h3>Moyen de paiement</h3>
                                </div>
                                <div className="col-xs-6">
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        Chèque
                                    </h3>
                                    <h3>
                                        <span className="payment-print-checkbox"></span>
                                        Espèces
                                    </h3>
                                </div>
                            </div>
                            <div className="row payment-print-checkbox-section">
                                <div className="col-xs-6">
                                    <h2>Commentaire.s :</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className="payment-page">
                    <div className="row wrapper border-bottom white-bg page-heading">
                        <div className="col-sm-12">
                            <h2>Règlements
                                concernant {this.props.user.first_name + " " + this.props.user.last_name}</h2>
                            {this.props.isStudentView ?
                                ""
                                :
                                <div className="flex flex-space-between-justified">
                                    <div className="flex flex-column">
                                        <button
                                            className="btn btn-primary m-t-sm"
                                            onClick={() => window.print()}
                                        >
                                            <i className="fas fa-print m-r-xs"/>
                                            Imprimer
                                        </button>
                                    </div>
                                    <div>
                                        <p>
                                            {this.state.scheduleStatus
                                                ? this.state.scheduleStatus.label
                                                : "Pas de Statut"}
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-xs btn-primary m-r-sm"
                                            data-toggle="modal"
                                            data-target="#statusModal"
                                        >
                                            Changer le statut
                                        </button>
                                        <div
                                            className="modal inmodal"
                                            id="statusModal"
                                            tabIndex="-1"
                                            role="dialog"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog">
                                                <div className="modal-content animated">
                                                    <div className="modal-header">
                                                        <p>Statut des règlements</p>
                                                    </div>
                                                    <div className="modal-body">
                                                        {generateStatusSelection}
                                                    </div>
                                                    <div className="modal-footer flex flex-space-between-justified">
                                                        <button
                                                            type="button"
                                                            className="btn"
                                                            data-dismiss="modal"
                                                        >
                                                            <i className="fas fa-times m-r-sm"></i>
                                                            Annuler
                                                        </button>
                                                        <button
                                                            className="btn btn-primary"
                                                            data-dismiss="modal"
                                                            onClick={() =>
                                                                this.handleSaveStatus()
                                                            }
                                                        >
                                                            <i className="fas fa-check m-r-sm"></i>
                                                            Valider
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                    <div className="padding-page m-b-md">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="form-group">
                                    <label>Saison</label>
                                    <select
                                        className="form-control"
                                        onChange={this.handleChangeSeason.bind(
                                            this
                                        )}
                                        value={this.state.season}
                                    >
                                        {this.props.seasons.map(s => (
                                            <option key={s.id} value={s.id}>
                                                {s.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <React.Fragment>
                            <div className="row">
                                <div className="col-lg-12">
                                    <ExtendedPaymentsSummary
                                        isInvoicesActivated={this.props.isInvoicesActivated}
                                        isStudentView={this.props.isStudentView}
                                        payers={this.state.payers}
                                        user={this.props.user}
                                        paymentMethods={this.props.paymentMethods}
                                        globalLocation={globalLocation}
                                        locations={this.props.locations}
                                        season={this.state.season}
                                        seasons={this.props.seasons}
                                        pricingCategories={this.props.pricingCategories}
                                        data={itemsForPayment}
                                        coupons={this.props.coupons}
                                        totalDue={isNaN(totalDue) ? null : totalDue}
                                        totalPayments={totalPayments}
                                        totalPayback={totalPayback}
                                        totalPaymentsToDay={totalPaymentsToDay}
                                        previsionalTotal={previsionalTotal}
                                        schedules={this.state.schedules}
                                        handleChangePricingChoice={(desId, userId, evt) => this.handleChangePricingChoice(desId, userId, evt)}
                                        handleChangePaymentMethod={(id, evt) => this.handleChangeActivityPaymentMethod(id, evt)}
                                        handleSwitchLocation={(id, location) => this.handleSwitchLocation(id, location)}
                                        handleChangePercentOffChoice={(discountable_id, discountable_type, couponId) => this.handleChangePercentOffChoice(discountable_id, discountable_type, couponId)}
                                        adhesionPrices={this.props.adhesionPrices}
                                        handleChangeAdhesionPricingChoice={(userId, evt) => this.handleChangeAdhesionPricingChoice(userId, evt)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <InvoicesSummary
                                        isInvoicesActivated={this.props.isInvoicesActivated}
                                        isStudentView={this.props.isStudentView}
                                        payers={this.state.payers}
                                        data={itemsForPayment}
                                        handleIssuedPaidInvoice={(payerId) => this.handleIssuedPaidInvoice(payerId)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {_.get(previousSeasonCreditNotes, "length") > 0 && (
                                    <div className="col-lg-4 col-md-6">
                                        <div className="alert alert-warning">
                                            <h4>
                                                Avoir sur {previousSeason.label}
                                            </h4>
                                            <ul>
                                                {previousSeasonCreditNotes.map(
                                                    p => (
                                                        <li key={p.id}>
                                                            {_.get(
                                                                p,
                                                                "payment_method.label"
                                                            )}{" "}
                                                            : {p.amount} €
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                                {previousSeasonBalance !== 0 && (
                                    <div className="col-lg-4 col-md-6">
                                        <div className="alert alert-warning">
                                            <h4>
                                                Solde non nul sur{" "}
                                                {previousSeason.label}
                                            </h4>
                                            <h3 className="no-margins">
                                                {previousSeasonBalance}€
                                            </h3>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>

                        {this.state.payers.length == 0 ?
                            "Aucun échéancier pour cette saison"
                            :
                            _.map(this.state.payers, payer => {
                                const previsionalTotal = _.chain(
                                    this.state.duePayments[payer.id]
                                )
                                    .values()
                                    .flatten()
                                    .map(dp => dp.amount)
                                    .reduce(
                                        (sum, n) =>
                                            (
                                                parseFloat(sum) + parseFloat(n)
                                            ).toFixed(2),
                                        "0"
                                    )
                                    .value();


                                return (
                                    <div className="row" key={payer.id}>
                                        <SwitchPayerModal
                                            payer={_.find(this.state.payers, {
                                                id: this.state.toSwitchPayerId,
                                            })}
                                            payers={this.state.payers}
                                            isOpen={Boolean(
                                                this.state.toSwitchPayerId
                                            )}
                                            onRequestClose={() =>
                                                this.handleSetToSwitchPayerId(null)
                                            }
                                            onSubmit={newPayerId =>
                                                this.handleSwitchPayer(newPayerId)
                                            }
                                        />
                                        <div className="col-lg-12 col-md-12 flex flex-center-aligned m-b-sm">
                                            <h3 className="m-r-sm">
                                                Paiements par{" "}
                                                <a
                                                    href={`/${payer.class_name == "User"
                                                        ? "users"
                                                        : "contacts"
                                                    }/${payer.id}`}
                                                >
                                                    {`${payer.first_name} ${payer.last_name} (${payer.class_name})`}
                                                </a>
                                            </h3>
                                            {this.state.payers.length > 1 &&
                                                <button
                                                    onClick={() =>
                                                        this.handleSetToSwitchPayerId(
                                                            payer.id
                                                        )
                                                    }
                                                    data-tippy-content="Changer le payeur"
                                                    data-toggle="modal"
                                                    data-target="#switch-payer-modal"
                                                    className="btn btn-sm btn-outline btn-primary"
                                                >
                                                    <i
                                                        className="fas fa-exchange-alt"
                                                        style={{marginRight: "0"}}
                                                    ></i>
                                                </button>
                                            }
                                        </div>

                                        <div className="col-lg-12 col-md-12 flex flex-center-aligned m-b-sm">
                                            {this.shouldRenderModalBillingPayers() &&
                                                <ModalBillingPayers payer={payer} datas={itemsForPayment}/>
                                            }

                                            {payer.payment_terms_summary &&
                                                <Fragment>
                                                    <div
                                                        className="col-lg-12 col-md-6 d-flex justify-content-between m-b-sm">
                                                        <div className="alert alert-info w-100">
                                                            Modalités de paiement souhaitées
                                                            : {payer.payment_terms_summary}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            }

                                            {
                                                this.state.payments[payer.id] && this.state.payments[payer.id].length > 0 &&
                                                <React.Fragment>
                                                    <div
                                                        className="col-lg-12 col-md-12 d-flex justify-content-between m-b-sm">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-xs"
                                                            onClick={() => window.open(`/payments/summary/${this.props.user.id}.pdf?season_id=${this.state.season}&payer_id=${payer.id}`)}>
                                                            <i className="fas fa-print text-primary mr-1"/>
                                                            Imprimer une attestation de paiement
                                                        </button>

                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-xs"
                                                            onClick={() => window.open(`/payment_schedule/${(this.state.schedules[payer.id] && this.state.schedules[payer.id].id)}.pdf`)}>
                                                            <i className="fas fa-print text-primary mr-1"/>
                                                            Imprimer l'échéancier
                                                        </button>
                                                    </div>

                                                    <div className="col-lg-12 col-md-12 print-none">
                                                        &nbsp;
                                                    </div>
                                                </React.Fragment>
                                            }
                                        </div>

                                        <div className="col-lg-6 col-md-6 m-b-lg print-none">
                                            <DuePaymentsList
                                                key={payer.id}
                                                adhesionEnabled={this.props.adhesionEnabled}
                                                data={this.state.duePayments[payer.id] || []}
                                                payer={payer}
                                                scheduleId={(this.state.schedules[payer.id] && this.state.schedules[payer.id].id) || null}
                                                payersNumber={this.state.payers.length}
                                                paymentMethods={this.props.paymentMethods}
                                                statuses={this.props.duePaymentStatuses}
                                                itemsForPayment={itemsForPayment}
                                                handleCreatePaymentSchedule={ps => this.handleCreatePaymentSchedule(ps)}
                                                handleCreatePayments={this.handleCreatePayments.bind(this)}
                                                handleSaveNewDuePayment={dp => this.handleSaveNewDuePayment(dp)}
                                                handleSaveDuePayment={(p, payerId) => this.handleSaveDuePayment(p, payerId)}
                                                handleDeleteDuePayment={(id, payerId) => this.handleDeleteDuePayment(id, payerId)}
                                                handleBulkDelete={this.handleBulkDeleteDuePayments.bind(this)}
                                                handleBulkEditCommit={this.handleBulkEditDuePayments.bind(this)}
                                                extraButtons={duePaymentListExtraButtons}
                                                isStudentView={this.props.isStudentView}
                                                seasonId={this.state.season}
                                            />{" "}
                                        </div>
                                        <div className="col-lg-6 col-md-6 m-b-lg print-none">
                                            <PaymentsList
                                                payer={payer}
                                                payments={this.state.payments[payer.id]}
                                                paymentMethods={this.props.paymentMethods}
                                                duePayments={this.state.duePayments[payer.id]}
                                                statuses={this.props.paymentStatuses}
                                                handleCreateNewPayment={p => this.handleCreateNewPayment(p)}
                                                handleDeletePayment={(id, payerId) => this.handleDeletePayment(id, payerId)}
                                                handleBulkDelete={this.handleBulkDeletePayments.bind(this)}
                                                handleBulkEditPayments={this.handleBulkEditPayments.bind(this)}
                                                handlePromptStatusEdit={this.handlePromptPaymentStatusEdit.bind(this)}
                                                extraButtons={paymentListExtraButtons}
                                                isStudentView={this.props.isStudentView}
                                            />
                                        </div>
                                    </div>
                                );
                            })}

                        {this.props.isStudentView ?
                            ""
                            :
                            <div className="row print-none">
                                <div className="col-lg-8 col-md-8">
                                    <CommentSection
                                        comments={this.state.comments}
                                        userId={this.props.user_id}
                                        contextType="PaymentSchedule"
                                        contextId={this.state.contextId}
                                        newComment={this.state.newComment}
                                        editedComment={this.state.editedComment}
                                        handleUpdateNewCommentContent={e =>
                                            this.handleUpdateNewCommentContent(e)
                                        }
                                        handleSaveComment={() =>
                                            this.handleSaveComment()
                                        }
                                        handleUpdateEditedCommentContent={e =>
                                            this.handleUpdateEditedCommentContent(e)
                                        }
                                        handleSaveCommentEdition={() =>
                                            this.handleSaveCommentEdition()
                                        }
                                        handleCommentEdition={id =>
                                            this.handleCommentEdition(id)
                                        }
                                    />
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </React.Fragment>
        );
    }

}