import React, {useState, useEffect} from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import * as api from "../../../../tools/api";
import moment from "moment";
import swal from "sweetalert2";

export default function InvoicesSummary(props) {
    const [invoiceData, setInvoiceData] = useState([]);
    const [creditNoteStatus, setCreditNoteStatus] = useState({});
    const payers = props.payers;

    useEffect(() => {
        if (payers) {
            payers.forEach((payer) => {
                api.set()
                    .success((res) => {
                        setInvoiceData((prevData) => [...prevData, ...res]);
                        checkCreditNote(res);
                    })
                    .error((res) => {
                        console.log("error", res);
                    })
                    .get("/api/student_payments/invoice/show", {payer: payer.id});
            });
        }
    }, [payers]);


    function checkCreditNote(invoices) {
        invoices.forEach((invoice) => {
            api.set()
                .success((res) => {
                    if (res.error) {
                        console.log("API error:", res.error);
                    } else if (res.has_credit_notes) {
                        setCreditNoteStatus((prevStatus) => ({
                            ...prevStatus,
                            [invoice.stripe_invoice_id]: res.credit_notes.stripe_credit_note_id
                        }));
                    }
                })
                .error((res) => {
                    console.log("Network error:", res);
                })
                .get(`/api/student_payments/credit_note/${invoice.stripe_invoice_id}`, {});
        });
    }

    function handleIssuedPaidInvoice(stripe_invoice_id, payer_id) {
        const invoiceId = stripe_invoice_id;
        swal.showLoading();
        api.set()
            .success(res => {
                const pdfUrl = res.url;
                if (pdfUrl) {
                    window.open(pdfUrl, "_blank");
                }

                swal({
                    title: "La facture a été éditée avec succès !",
                    type: "success",
                }).then(() => {
                    window.location.reload();
                });
            })
            .error((res) => {
                swal({
                    title: res.error,
                    type: "error",
                    text: res.message
                });
            })
            .post(
                `/api/student_payments/invoice/issued_paid_invoice`,
                {
                    invoiceId: invoiceId,
                    payerId: payer_id
                }
            );
    }


    function showIssuedInvoice(stripe_invoice_id) {
        swal.showLoading();
        api.set()
            .success(res => {
                const pdfUrl = res.url;
                if (pdfUrl) {
                    swal.close();
                    window.open(pdfUrl, "_blank");
                }
            })
            .error((error) => {
                swal({
                    title: "Erreur",
                    text: "Une erreur s'est produite lors du téléchargement de la facture",
                    type: "error"
                });
            })
            .get(`/api/student_payments/stripe/issued_invoice/${stripe_invoice_id}`, {});
    }

    function downloadInvoice(stripe_invoice_id) {
        swal.showLoading();
        api.set()
            .success((data) => {
                if (data.url) {
                    swal.close();
                    window.location = data.url;
                }
            })
            .error((error) => {
                swal({
                    title: "Erreur",
                    text: "Une erreur s'est produite lors de la création de la facture",
                    type: "error"
                });
            })
            .get(`/api/student_payments/stripe/invoice/${stripe_invoice_id}`, {});
    }

    function downloadCreditNote(stripe_credit_note_id) {
        swal.showLoading();

        api.set()
            .success((data) => {
                if (data.url) {
                    swal.close();
                    window.location = data.url;
                }
            })
            .error((error) => {
                swal({
                    title: "Erreur",
                    text: "Une erreur s'est produite lors de la création de la facture",
                    type: "error"
                });
            })
            .get(`/api/student_payments/stripe/credit_note/${stripe_credit_note_id}`, {});
    }

    const columns = [
        {
            Header: "Statut",
            accessor: "status",
            Cell: row => {
                return row.original.edited ?
                    <p className="badge my-auto"
                       style={{background: "#ed558a", color: "white"}}>Acquittée</p> :
                    row.value === "paid" ?
                        <p className="badge my-auto"
                           style={{background: "rgb(26, 179, 148)", color: "white"}}>Payée</p> :
                        <p className="badge my-auto">Ouverte</p>
            },
            className: "text-center",
            width: 100,
        },
        {
            Header: "Date",
            accessor: "created_at",
            Cell: row => {
                return moment(row.value).format("DD/MM/YYYY");
            },
            width: 150,
        },
        {
            Header: "Numéro",
            accessor: "number",

        },
        {
            Header: "Montant",
            accessor: "amount_due",
            Cell: row => {
                const amount = parseFloat(row.value);
                const isInteger = Number.isInteger(amount);
                return isInteger ? Math.floor(amount) + " €" : amount.toFixed(2) + " €";
            },
            width: 100,
            className: "text-right"
        },
        {
            Header: "Visualisation",
            accessor: "stripe_invoice_id",
            Cell: row => {
                return (
                    <div className="">
                        {
                            creditNoteStatus[row.original.stripe_invoice_id] && (
                                <button className="btn btn-danger btn-xs my-auto"
                                        onClick={() => downloadCreditNote(creditNoteStatus[row.original.stripe_invoice_id])}>
                                    <i className="fas fa-file-pdf m-r-xs"/>
                                    Avoir
                                </button>
                            )
                        }
                        <button className="btn btn-primary btn-xs my-auto ml-2"
                                onClick={() => row.original.edited ? showIssuedInvoice(row.original.stripe_invoice_id) : downloadInvoice(row.original.stripe_invoice_id)}>
                            <i className="fas fa-file-pdf m-r-xs"/>
                            Facture
                        </button>

                    </div>
                )
            },
            className: "text-right",
        },
        {
            Header: "Actions",
            accessor: "stripe_invoice_id",
            Cell: row => {
                return (
                    <div className="">
                        {row.original.edited === null && (
                            <button className="btn btn-xs my-auto "
                                    onClick={() => handleIssuedPaidInvoice(row.original.stripe_invoice_id, row.original.customer.id)}
                                    style={{color: "rgb(0, 111, 176)", borderColor: "rgb(0, 111, 176)", backgroundColor: "white"}}>
                                <i className="fas fa-file-invoice m-r-xs"/>
                                Générer la facture acquittée
                            </button>
                        )}
                    </div>
                );
            },
            className: "text-center",
        }

    ];

    return (
        <div className="ibox m-b-md">
            <div className="ibox-title">
                <h4>Factures</h4>
            </div>
            <ReactTable
                data={invoiceData}
                columns={columns}
                defaultSorted={[{id: "id", desc: true}]}
                resizable={false}
                previousText="Précedent"
                nextText="Suivant"
                loadingText="Chargement..."
                noDataText="Aucune donnée"
                pageText="Page"
                ofText="sur"
                rowsText="résultats"
                minRows={1}
                showPagination={false}
                className="whitebg"
            />
        </div>
    );
}
