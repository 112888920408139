import React, { Fragment } from 'react';
import Modal from "react-modal";
import swal from "sweetalert2";
import { csrfToken } from "../../utils";
import InvoiceHeaderComponent from "./InvoiceHeaderComponent";

export default class SubscriptionComponent extends React.Component {
    constructor(props) {
        super(props);

        const expiry_tab = ((this.props.license || {}).days_before_end || "").split(".");

        this.state = {
            expiry_days: parseInt(expiry_tab[0] || "0"),
            expiry_hours: parseInt(expiry_tab[1] || "0"),
            licence_canceled_at: (this.props.license || {}).cancel_at ? new Date(parseInt(this.props.license.cancel_at) * 1000) : undefined,
            showPlanModal: false,
            showInvoiceHeaderModal: false,
            legalEntity: this.props.legalEntity,
            siretRna: this.props.siretRna,
            address: this.props.address,
            telephone: this.props.telephone,
            email: this.props.email,
            tax_exempt: this.props.tax_exempt,
            tax_id: this.props.tax_id,
            currentPrice: [...this.props.prices].find(p => p.is_current)
        };

        this.is_expired = this.is_expired.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
        this.changePlan = this.changePlan.bind(this);
        this.subscribePlan = this.subscribePlan.bind(this);
        //this.ensureInvoiceHeaderInfoThenSubscribe = this.ensureInvoiceHeaderInfoThenSubscribe.bind(this);
        this.closePlanModal = this.closePlanModal.bind(this);
        this.closeInvoiceHeaderModal = this.closeInvoiceHeaderModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.legalEntity !== this.props.legalEntity) {
            this.setState({ legalEntity: this.props.legalEntity })
        }
        if (prevProps.siretRna !== this.props.siretRna) {
            this.setState({ siretRna: this.props.siretRna })
        }
        if (prevProps.address !== this.props.address) {
            this.setState({ address: this.props.address })
        }
        if (prevProps.telephone !== this.props.telephone) {
            this.setState({ telephone: this.props.telephone })
        }
        if (prevProps.email !== this.props.email) {
            this.setState({ email: this.props.email })
        }
        if (prevProps.tax_exempt !== this.props.tax_exempt) {
            this.setState({ tax_exempt: this.props.tax_exempt })
        }
        if (prevProps.tax_id !== this.props.tax_id) {
            this.setState({ tax_id: this.props.tax_id })
        }
    }

    render() {
        //const currentPrice = [...this.props.prices].find(p => p.is_current);

        const interval_count = (this.state.currentPrice ? parseInt(this.state.currentPrice.recurring.split("|")[0]) : undefined) || 1;
        const interval = (this.state.currentPrice ? this.state.currentPrice.recurring.split("|")[1] : undefined) || "mois";

        const subscriptionId = (this.props.license || {}).subscription_id || "";
        const date = this.props.license ? new Date(this.props.license.end_date) : undefined;
        const expirationDate =
            (this.props.license || {}).end_date ?
                `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` :
                "";

        const gotSubscription = this.props.license
            && this.props.license.subscription_id
            && this.props.license.subscription_id.startsWith("stripe/");

        return <div className="ibox">
            <div className="ibox-title">
                <h3 style={{width: "100%"}}>
                    Votre plan
                </h3>
            </div>

            <div className="ibox-content">
                <Fragment>
                    {this.state.currentPrice ?
                        <Fragment>
                            <p><b>{this.state.currentPrice.name} ({this.state.currentPrice.desc})</b></p>
                            <p className="text-secondary">
                                {this.state.currentPrice.amount} {this.state.currentPrice.currency} {this.state.currentPrice.tax_suffix} {interval_count > 1 ? `tous les ${interval_count}` : "par"} {interval}
                            </p>
                        </Fragment>
                        :
                        <p>
                            Aucun tarif sélectionné
                        </p>
                    }

                    {/**********************************************/}
                    {/* si licence d'évaluation ou pas de licence*/}
                    {subscriptionId == "" || subscriptionId.startsWith("cockpit") ?
                        <div>
                            {subscriptionId == "" ?
                                "Pas de licence valide..."
                                :
                                <p className={this.is_expired() ? "text-danger" : "text-secondary"}>
                                    {this.is_expired() ?
                                        `Licence d'évaluation expirée depuis le ${expirationDate}`
                                        :
                                        `Licence d'évaluation valide jusqu'au ${expirationDate}`
                                    }
                                </p>

                            }
                        </div>

                        :
                        <Fragment>
                            <p className={this.is_expired() ? "text-danger" : "text-secondary"}>
                                {
                                    this.is_expired() ?
                                        `Votre abonnement a expiré le ${new Date(Date.now() + (this.state.expiry_days * 86400000)).toLocaleDateString()}`
                                        :
                                        this.state.expiry_days < 7 ?
                                            <Fragment><span className="text-warning">Votre abonnement arrive bientôt à expiration.</span>
                                                <br/></Fragment>
                                            :
                                            ""
                                }
                                {
                                    this.is_expired() ? "" : this.state.expiry_days >= 31 ?
                                        this.state.licence_canceled_at ? `Votre abonnement prend fin le ${this.state.licence_canceled_at.toLocaleDateString()}` : `Prochaine facturation le ${new Date(Date.now() + (this.state.expiry_days * 86400000)).toLocaleDateString()}`
                                        :
                                        this.state.licence_canceled_at ? `Votre abonnement prend fin dans ${Math.ceil((this.state.licence_canceled_at.getTime() - Date.now()) / 86400000)} jours` : `Prochaine facturation dans ${this.state.expiry_days} jours et ${this.state.expiry_hours} heures`
                                }
                            </p>
                        </Fragment>}
                </Fragment>

            </div>

            <div className="ibox-footer">
                <div className="row">
                    <div className="col-sm-6">
                        {gotSubscription ?
                            ""
                            :
                            <button
                                className="btn btn-primary btn-block"
                                type="button"
                                onClick={() => this.setState({showInvoiceHeaderModal: true})}
                            >
                                Payer
                            </button>
                        }
                    </div>

                    <div className="col-sm-6">
                        <button
                            className="btn btn-primary btn-block"
                            type="button"
                            onClick={() => this.setState({showPlanModal: true})}
                        >
                            Changer le plan
                        </button>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={this.state.showInvoiceHeaderModal}
                contentLabel="Coordonnées de facturation"
                style={{
                    content: {
                        inset: "auto",
                        minWidth: "50%",
                    }
                }}
                ariaHideApp={false}
                onRequestClose={() => this.closeInvoiceHeaderModal()}>
                <h2 className="fas fa-times position-absolute"
                    style={{cursor: "pointer", position: "absolute", right: "10px", top: "5px", margin: "0"}}
                    onClick={this.closeInvoiceHeaderModal}/>

                <div className="row m-b-md">
                    <div className="col-sm-12 text-center">
                        <h3><b>Merci de renseigner vos coordonnées de facturation</b></h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <InvoiceHeaderComponent
                            legalEntity={this.state.legalEntity}
                            siretRna={this.state.siretRna}
                            telephone={this.state.telephone}
                            address={this.state.address}
                            email={this.state.email}
                            tax_exempt={this.state.tax_exempt}
                            tax_id={this.state.tax_id}
                            countries={this.props.countries}
                            submitCaption={"Valider mes coordonnées et payer mon abonnement"}
                            onSaveInvoiceHeader={(invoicing) => this.changeInvoiceHeader(invoicing)}
                        />
                    </div>
                </div>

            </Modal>


            <Modal
                isOpen={this.state.showPlanModal}
                contentLabel="Liste des plans"
                style={{
                    content: {
                        inset: "auto",
                        minWidth: "50%",
                    }
                }}
                ariaHideApp={false}
                onRequestClose={() => this.closePlanModal()}>
                <h2 className="fas fa-times position-absolute"
                    style={{cursor: "pointer", position: "absolute", right: "10px", top: "5px", margin: "0"}}
                    onClick={this.closePlanModal}/>

                <div className="row m-b-md">
                    <div className="col-sm-12 text-center">
                        <h3><b>Choisissez le plan qui vous convient</b></h3>
                    </div>
                </div>

                <div className="row m-b-md">
                    {this.props.prices.map((p, i) =>
                        {
                            return p.active || p.is_current ?
                                <div key={`plan-${i}`} className={`text-center m-b-md col-${this.props.prices.length > 5 ? 'sm-3' : 'bs'}`}>
                                    <div className="card" style={{
                                        maxWidth: "220px",
                                        borderColor: p.price_id === (this.state.currentPrice || {}).price_id ? 'red' : 'gainsboro'
                                    }}>
                                        <h4 className="card-header text-center">{p.name}</h4>

                                        <div className="text-center card-body">
                                            <b>{p.amount} {p.currency}</b> <br/>

                                            {interval_count > 1 ? ("tous les " + interval_count) : "par"} {interval} <br/>
                                            sans engagement<br/>
                                            <br/>
                                            {p.desc.replace("\n", "<br/>")}

                                            <br/>
                                            <br/>

                                            {p.price_id === (this.state.currentPrice || {}).price_id ? <Fragment>
                                                    <button className="btn btn-primary text-white" disabled={true}>
                                                        Plan choisi
                                                    </button>

                                                    {this.state.licence_canceled_at ? "" : <Fragment>
                                                        <br/>
                                                        <a href="#" className="link-not-visible"
                                                           onClick={() => this.cancelSubscription()}>annuler l'abonnement</a>
                                                    </Fragment>}
                                                </Fragment> :
                                                <span data-tippy-content={p.max_teachers && p.max_teachers < this.props.current_nb_teachers ? "Nombre actuel de professeur supérieur au nombre limite de ce plan" : undefined} tabIndex={0}>
                                            <button className="btn btn-primary text-white"
                                                    onClick={() => {
                                                        if(!p.max_teachers || p.max_teachers >= this.props.current_nb_teachers)
                                                            this.changePlan(p.price_id)
                                                    }}
                                                    disabled={p.max_teachers && p.max_teachers < this.props.current_nb_teachers}>
                                                Choisir
                                            </button>
                                        </span>}
                                        </div>
                                    </div>

                                    {p.price_id === (this.state.currentPrice || {}).price_id ? this.is_expired() ?
                                        <p className="text-danger">Votre abonnement a expiré
                                            le {new Date(Date.now() + (this.state.expiry_days * 86400000)).toLocaleDateString()}</p> :
                                        <div style={{maxWidth: "220px"}}>
                                            {this.state.expiry_days < 7 ?
                                                <p className="text-danger">Votre abonnement arrive bientôt à expiration.</p> : ""}
                                            {this.state.expiry_days >= 31 ?
                                                <p>Date
                                                    d'expiration: {new Date(Date.now() + (this.state.expiry_days * 86400000)).toLocaleDateString()}</p> :
                                                <p>Temps avant expiration de la licence: {this.state.expiry_days} jours
                                                    et {this.state.expiry_hours} heures</p>}
                                        </div> : ""}
                                </div>
                                : ""}
                    )}
                </div>
            </Modal>
        </div>;
    }

    closePlanModal() {
        this.setState({ showPlanModal: false })
    }

    closeInvoiceHeaderModal() {
        this.setState({ showInvoiceHeaderModal: false })
    }

    /**
     *
     * @returns {boolean}
     */
    is_expired() {
        return this.state.expiry_days <= 0 && this.state.expiry_hours <= 0;
    }

    cancelSubscription() {
        swal({
            type: "warning",
            title: "L'abonnement sera annulé à la fin de la période en cours (la licence ne sera plus renouvelée). Voulez-vous continuer ?",
            showCancelButton: true,
            cancelButtonText: "Non",
            confirmButtonText: "Oui"
        }).then(r => {
            if (r.value) {
                fetch("/elvis_subscription_management/cancel_subscription", {
                    method: "DELETE",
                    headers: {
                        "X-CSRF-Token": csrfToken
                    }
                }).then(response => response.json()).then(json => {
                    this.setState({ licence_canceled_at: new Date(Date.now() + (this.state.expiry_days * 86400000)) });

                    swal({
                        type: 'success',
                        title: "l'abonnement a été annulé."
                    }).then(() => this.closePlanModal());
                })
            }
        });
    }

    changePlan(pid) {
        if (pid == undefined)
            return;

        //const cp = this.props.prices.find(p => p.is_current);
        const p = this.props.prices.find(p => p.price_id == pid);

        if (p == undefined)
            return;

        // si on a déjà une souscription Stripe, il faut la changer
        if (this.props.license
            && this.props.license.subscription_id
            && this.props.license.subscription_id.startsWith("stripe/")) {
            swal({
                type: "warning",
                title: "Voulez-vous vraiment changer de plan ?",
                text: `L'abonnement "${p.name}" sera effectif dès maintenant. La différence de prix sera ajoutée ou soustraite selon le plan choisi lors de la prochaine facturation.`,
                showCancelButton: true,
                cancelButtonText: "Non",
                confirmButtonText: "Oui"
            }).then(res => {
                if (res.value) {
                    fetch("/elvis_subscription_management/change_plan", {
                        method: "POST",
                        headers: {
                            "X-CSRF-Token": csrfToken,
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({ pid: pid })
                    }).then(response => response.json()).then(json => {
                        const isSuccess = !json.message;

                        swal({
                            type: isSuccess ? "success" : "error",
                            title: isSuccess ? "changement de plan effectué" : "une erreur est survenue, ressayez plus tard ou contactez un administrateur.",
                            text: isSuccess ? "la nouvelle licence est en cours de génération." : json.message
                        });

                        this.setState({
                            showPlanModal: false,
                            currentPrice: p
                        })
                        this.props.changeSubscriptionSucceded();
                    });
                }
            });
        }

        // si on n'a pas encore de souscription Stripe, on enregistre le pricing choisi
        else {
            fetch("/elvis_subscription_management/license/selected_price_id", {
                method: "POST",
                headers: {
                    "X-CSRF-Token": csrfToken,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ selected_price_id: pid })
            }).then(response => response.json()).then(json => {
                const isSuccess = !json.message;
                console.log(json);

                swal({
                    type: isSuccess ? "success" : "error",
                    title: isSuccess ? "Changement de plan effectué" : "Une erreur est survenue, réessayez plus tard ou contactez un administrateur.",
                    text: isSuccess ? "Parfait ! Il ne vous reste plus qu'à payer l'abonnement." : json.message
                });

                this.setState({
                    showPlanModal: false,
                    currentPrice: p
                })
            });

        }


    }

    gotInvoiceHeaderInfo() {
        return (
            this.state.legalEntity &&
            this.state.siretRna &&
            this.state.address &&
            this.state.email &&
            this.state.tax_exempt &&
            (this.state.tax_exempt=='none' || this.state.tax_id!==undefined)    // si tax_exempt vaut 'exempt', inutile de vérifier tax_id
        );
    }


    /**
     *
     * @param {string} price_id
     */
    subscribePlan() {

        const price_id = this.state.currentPrice.price_id
        fetch(`/elvis_subscription_management/create_pay_link?id=${price_id}`, {
            method: "GET",
            headers: {
                "X-CSRF-Token": csrfToken,
                "Content-Type": "application/json"
            }
        }).then(res => res.json()).then(json => window.location = json.url);

    }

    changeInvoiceHeader(invoicing) {
        const state = {
            legalEntity: invoicing.legalEntity,
            siretRna: invoicing.siretRna,
            address: invoicing.address,
            telephone: invoicing.telephone,
            email: invoicing.email,
            tax_exempt: invoicing.tax_exempt,
            tax_id: invoicing.tax_id,
        }

        this.setState(state);
        this.props.onSaveInvoiceHeader(state);

        this.closeInvoiceHeaderModal();
        if (this.gotInvoiceHeaderInfo())
            this.subscribePlan();
    }
}