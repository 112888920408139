import React, {Fragment} from "react";
import swal from "sweetalert2";
import {makeDebounce} from "./../../../../tools/inputs";
import BaseDataTable from "../../../common/baseDataTable/BaseDataTable";
import DataService from "../../../common/baseDataTable/DataService";
import * as StripeHelpers from "../stripeUtils/StripeHelpers";
import {formatFloatWithTwoDecimal, formatStringifiedFloatAsCurrency} from "../stripeUtils/StripeHelpers";
import * as api from "../../../../tools/api";


const moment = require("moment");
require("moment/locale/fr");

export default class StripeCreditNotesList extends React.Component {
    STATUS_MAPPING = {
        issued: "émis",
        void: "annulé",
    };

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pages: null,
            sorted: '',
            loading: true,
            filter: {},
            customerList: [{label: 'Chargement', value: -1}],
            showModal: {value: false},
            loadCsv: false,
            loadPDF: {stripeCreditNoteId: null, loading: false},
            delete: {stripeCreditNoteId: null, loading: false},
        };

    }

    downloadPDF(url) {
        if (!url) return;

        window.open(url, '_blank');
        this.setState({loadPDF: {stripeCreditNoteId: null, loading: false}})
    }


    render() {
        const columns = [
            {
                id: "created",

                Header: "Créé le",
                accessor: d => {
                    let dateFormat = new Date(d.created)
                    //const date = dateFormat.getDate() + " " + months[dateFormat.getMonth()] + " " + dateFormat.getFullYear()
                    return dateFormat.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
                },
                filterable: false,
            },
            {
                id: "number",
                Header: "Numéro d'avoir",
                accessor: "number",
                filterable: false,
            },
            {
                id: "customer_name",
                Header: "Client",
                accessor: "customer_name",
                filterable: false,
            },
            {
                id: "status",
                Header: "Statut",
                accessor: row => this.STATUS_MAPPING[row.status],
                filterable: false,
            },
            {
                id: "amount",
                Header: "Montant TTC",
                accessor: "amount",
                Cell: props => (
                    <div className="text-right">
                        {formatStringifiedFloatAsCurrency(props.original.amount)}
                    </div>),
                filterable: false,
            },
            {
                id: "stripe_invoice.number",
                Header: "Facture",
                accessor: "stripe_invoice.number",
                Cell: props => (
                    <Fragment>
                        <a href={`/stripe_invoices/${props.original.stripe_invoice_id}`} target='_blank'>
                            {props.original.stripe_invoice.number}
                            &nbsp;
                            <i className="fas fa-external-link-alt"/>

                        </a>
                        &nbsp;
                        ({formatStringifiedFloatAsCurrency(props.original.stripe_invoice.total)})
                    </Fragment>
                ),
                filterable: false,
            },
            {
                id: "actions",
                Header: "Actions",
                Cell: props => {
                    return (
                        <div className="text-center">
                            <div className="btn-wrapper">
                                <a
                                    id={`pdf_${props.original.stripe_credit_note_id}`}
                                    key={`pdf_${props.original.stripe_credit_note_id}`}
                                    name={`pdf_${props.original.stripe_credit_note_id}`}
                                    className="btn-sm btn-primary m-r-sm"
                                    data-tippy-content="Télécharger le pdf"
                                    disabled={this.state.loadPDF.loading} onClick={() => {
                                    this.setState({
                                        loadPDF: {
                                            creditNoteId: props.original.stripe_credit_note_id,
                                            loading: true
                                        }
                                    })
                                    this.downloadPDF(props.original.pdf)
                                }}>
                                    {this.state.loadPDF.loading && this.state.loadPDF.creditNoteId === props.original.stripe_credit_note_id ?
                                        <i className="fas fa-circle-notch fa-spin"/> :
                                        <i className="fas fa-file-download"/>}
                                </a>
                            </div>
                        </div>)
                },
                sortable: false,
                filterable: false,
            }
        ];

        return (
            <Fragment>
                <div className="row m-xs">
                    <div className="col-lg-12">
                        <div className="ibox">
                            <div className="ibox-content">
                                <BaseDataTable
                                    dataService={new DataService("/stripe_credit_notes")}
                                    columns={columns}
                                    actionButtons={null}
                                    createButton={null}
                                    formContentComponent={null}
                                    showFullScreenButton={false}
                                    //labellizer={coupon => `${coupon.label} (-${coupon.percent_off}%)`}
                                    oneResourceTypeName="un avoir"
                                    thisResourceTypeName="cet avoir"
                                    defaultSorted={[{id: "created", asc: false}]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );

    }
}



